define("ember-power-select/helpers/ember-power-select-is-group", ["exports", "@ember/component/helper", "ember-power-select/utils/group-utils"], function (_exports, _helper, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectIsGroup = emberPowerSelectIsGroup;

  function emberPowerSelectIsGroup(_ref) {
    let [maybeGroup] = _ref;
    return (0, _groupUtils.isGroup)(maybeGroup);
  }

  var _default = (0, _helper.helper)(emberPowerSelectIsGroup);

  _exports.default = _default;
});