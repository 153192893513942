define("ember-changeset/utils/is-promise", ["exports", "ember-changeset/utils/is-object"], function (_exports, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPromise;

  function isPromiseLike(obj) {
    return !!obj && !!obj.then && !!obj.catch && !!obj.finally && typeof obj.then === 'function' && typeof obj.catch === 'function' && typeof obj.finally === 'function';
  }

  function isPromise(obj) {
    return (0, _isObject.default)(obj) && isPromiseLike(obj);
  }
});