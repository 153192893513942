define("ember-changeset-validations/utils/wrap", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wrapInArray;

  /**
   * Wraps a value in an Ember.Array.
   *
   * @public
   * @param  {Any} value
   * @return {Ember.Array}
   */
  function wrapInArray(value) {
    if ((0, _array.isArray)(value)) {
      return (0, _array.A)(value);
    }

    return (0, _array.A)([value]);
  }
});